import { PageEnum, RouterNameEnum } from '@/types/enums/pageEnum'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export const asyncTopRoutesList: RouteRecordRaw[] = [
  {
    path: PageEnum.BASE_DASHBOARD,
    name: RouterNameEnum.BASE_DASHBOARD,
    meta: {
      layout: 'dashboardLayout',
      icon: 'kopic-shouye',
      title: '首页',
    },
    component: () => import('@/views/dashboard/index.vue'),
  },
  {
    path: PageEnum.TRANSACTION,
    name: RouterNameEnum.TRANSACTION,
    meta: {
      icon: 'kopic-jiaoyi',
      title: '交易',
    },
    redirect: PageEnum.BUSINESS,
    children: [
      {
        path: PageEnum.ALL_ORDER,
        name: RouterNameEnum.ALL_ORDER,
        meta: {
          title: '全部订单',
          isHideChildren: true,
        },
        redirect: (to: RouteLocation) => ({
          path: PageEnum.BUSINESS,
          query: to.query,
          params: to.params,
        }),
        children: [
          {
            path: PageEnum.ALL_ORDER,
            name: RouterNameEnum.ALL_ORDER,
            meta: {
              title: '全部订单',
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/business/all.vue'),
          },
          {
            path: PageEnum.ALL_ORDER_DETAIL,
            name: RouterNameEnum.ALL_ORDER_DETAIL,
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/bill/details/_id.vue'),
          },
        ],
      },
      {
        path: PageEnum.BUSINESS,
        name: RouterNameEnum.BUSINESS,
        meta: {
          title: '交易明细',
          isHideChildren: true, // 子级不展示在权限管理页面的菜单管理中
        },
        redirect: (to: RouteLocation) => ({
          path: PageEnum.BUSINESS,
          query: to.query,
          params: to.params,
        }),
        children: [
          {
            path: PageEnum.BUSINESS,
            name: PageEnum.BUSINESS,
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/business/index.vue'),
          },
          {
            path: PageEnum.BUSINESS_DETAIL,
            name: RouterNameEnum.BUSINESS_DETAIL,
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/business/detail/_id.vue'),
          },
          {
            path: PageEnum.BUSINESS_DELIVERY,
            name: RouterNameEnum.BUSINESS_DELIVERY,
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/business/delivery/_id.vue'),
          },
        ],
      },
      // {
      //   path: PageEnum.MERCHANT_SETTLEMENT,
      //   name: RouterNameEnum.MERCHANT_SETTLEMENT,
      //   meta: {
      //     layout: 'dashboardLayout',
      //     title: '结算',
      //   },
      //   component: () => import('@/views/merchantSettlement/index.vue'),
      // },
      {
        path: PageEnum.CHARGEBACK,
        name: RouterNameEnum.CHARGEBACK,
        meta: {
          layout: 'dashboardLayout',
          title: '退款',
        },
        component: () => import('@/views/chargeback/index.vue'),
      },
      {
        path: PageEnum.BILL_REISSUE,
        name: RouterNameEnum.BILL_REISSUE,
        meta: {
          layout: 'dashboardLayout',
          title: '漏单补发',
        },
        component: () => import('@/views/billReissue/index.vue'),
      },
    ],
  },
  {
    path: PageEnum.BILL,
    name: RouterNameEnum.BILL,
    meta: {
      icon: 'kopic-dingdan',
      title: '订单明细',
      isHideChildren: true, // 子级不展示在权限管理页面的菜单管理中
    },
    redirect: (to: RouteLocation) => ({
      path: PageEnum.BILL,
      query: to.query,
      params: to.params,
    }),
    children: [
      {
        path: PageEnum.BILL,
        name: PageEnum.BILL,
        meta: {
          layout: 'dashboardLayout',
        },
        component: () => import('@/views/bill/index.vue'),
      },
      {
        path: PageEnum.BILL_DETAIL,
        name: RouterNameEnum.BILL_DETAIL,
        meta: {
          layout: 'dashboardLayout',
        },
        component: () => import('@/views/bill/details/_id.vue'),
      },
    ],
  },
  {
    path: PageEnum.GIVEAWAY_RECORD,
    name: RouterNameEnum.GIVEAWAY_RECORD,
    meta: {
      layout: 'dashboardLayout',
      title: '赠品记录',
      icon: 'kopic-ic_zengpinjilu',
    },
    component: () => import('@/views/giveawayRecord/index.vue'),
  },
  {
    path: PageEnum.DATA_SEARCH,
    name: RouterNameEnum.DATA_SEARCH,
    meta: {
      icon: 'kopic-yonghushujuchaxun',
      title: '用户数据查询',
    },
    children: [
      {
        path: PageEnum.DATA_ANALYZE,
        name: RouterNameEnum.DATA_ANALYZE,
        meta: {
          layout: 'dashboardLayout',
          title: '付费数据',
        },
        component: () => import('@/views/dataAnalyze/index.vue'),
      },
      {
        path: PageEnum.REFUND_ANALYZE,
        name: RouterNameEnum.REFUND_ANALYZE,
        meta: {
          layout: 'dashboardLayout',
          title: '退款数据',
        },
        component: () => import('@/views/refundAnalyze/index.vue'),
      },
      {
        path: PageEnum.PAY_USERS,
        name: RouterNameEnum.PAY_USERS,
        meta: {
          layout: 'dashboardLayout',
          title: '当日数据',
        },
        component: () => import('@/views/payingUsers/index.vue'),
      },
      {
        path: PageEnum.CONVERSION_RATE,
        name: RouterNameEnum.CONVERSION_RATE,
        meta: {
          layout: 'dashboardLayout',
          title: '转化率',
        },
        component: () => import('@/views/conversionRate/index.vue'),
      },
      {
        path: PageEnum.PAYMENT_ACTIVITIES,
        name: RouterNameEnum.PAYMENT_ACTIVITIES,
        meta: {
          layout: 'dashboardLayout',
          title: '付费活跃数据',
        },
        component: () => import('@/views/paymentActivities/index.vue'),
      },
      {
        path: PageEnum.PROP_ANALYSIS,
        name: RouterNameEnum.PROP_ANALYSIS,
        meta: {
          layout: 'dashboardLayout',
          title: '道具购买数据',
        },
        component: () => import('@/views/propAnalysis/index.vue'),
      },
      {
        path: PageEnum.NEW_DATA_ANALYZE,
        name: RouterNameEnum.NEW_DATA_ANALYZE,
        meta: {
          layout: 'dashboardLayout',
          title: '破冰用户数据',
        },
        component: () => import('@/views/newDataAnalyze/index.vue'),
      },
      {
        path: PageEnum.USER_PAY_TOP,
        name: RouterNameEnum.USER_PAY_TOP,
        meta: {
          layout: 'dashboardLayout',
          title: '用户付费排行',
        },
        component: () => import('@/views/userPayTop/index.vue'),
      },
      {
        path: PageEnum.GOODS_SALES_TOP,
        name: RouterNameEnum.GOODS_SALES_TOP,
        meta: {
          layout: 'dashboardLayout',
          title: '道具用户购买排行',
        },
        component: () => import('@/views/goodsSalesTop/index.vue'),
      },
    ],
  },
  {
    path: PageEnum.ANALYZE,
    name: RouterNameEnum.ANALYZE,
    meta: {
      icon: 'kopic-fenxi',
      title: '运营活动数据',
    },
    children: [
      {
        path: PageEnum.ACTIVITIES_DATA,
        name: RouterNameEnum.ACTIVITIES_DATA,
        meta: {
          layout: 'dashboardLayout',
          title: '活动数据',
          isHideChildren: true, // 子级不展示在权限管理页面的菜单管理中
        },
        redirect: (to: RouteLocation) => ({
          path: PageEnum.ACTIVITIES_DATA,
          query: to.query,
          params: to.params,
        }),
        children: [
          {
            path: PageEnum.ACTIVITIES_DATA,
            name: PageEnum.ACTIVITIES_DATA,
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/activitiesData/index.vue'),
          },
          {
            path: PageEnum.ACTIVITIES_DATA_DETAIL,
            name: RouterNameEnum.ACTIVITIES_DATA_DETAIL,
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/activitiesData/detail.vue'),
          },
          {
            path: PageEnum.ACTIVITIES_DATA_DETAIL2,
            name: RouterNameEnum.ACTIVITIES_DATA_DETAIL2,
            meta: {
              layout: 'dashboardLayout',
            },
            component: () => import('@/views/activitiesData/detail2.vue'),
          },
        ],
      },
      {
        path: PageEnum.VIP_DATA,
        name: RouterNameEnum.VIP_DATA,
        meta: {
          layout: 'dashboardLayout',
          title: '会员中心数据',
        },
        component: () => import('@/views/vipData/index.vue'),
      },
      {
        path: PageEnum.SIGN_IN_DATA,
        name: RouterNameEnum.SIGN_IN_DATA,
        meta: {
          layout: 'dashboardLayout',
          title: '每日签到数据',
        },
        component: () => import('@/views/signInData/index.vue'),
      },
      {
        path: PageEnum.INTEG_TASK_DATA,
        name: RouterNameEnum.INTEG_TASK_DATA,
        meta: {
          layout: 'dashboardLayout',
          title: '积分任务完成数据',
        },
        component: () => import('@/views/integralData/task.vue'),
      },
      {
        path: PageEnum.INTEG_GOODS_DATA,
        name: RouterNameEnum.INTEG_GOODS_DATA,
        meta: {
          layout: 'dashboardLayout',
          title: '积分商品兑换数据',
        },
        component: () => import('@/views/integralData/goods.vue'),
      },
      {
        path: PageEnum.PROMOTER_DATA,
        name: RouterNameEnum.PROMOTER_DATA,
        meta: {
          layout: 'dashboardLayout',
          title: '分享拉新数据',
        },
        component: () => import('@/views/promoterData/index.vue'),
      },
    ],
  },
  {
    path: PageEnum.CHANNEL_DATA,
    name: RouterNameEnum.CHANNEL_DATA,
    meta: {
      icon: 'kopic-qudaoshuju',
      title: '渠道数据',
    },
    redirect: PageEnum.RATE_FLOAT,
    children: [
      {
        path: PageEnum.DATA_OVERVIEW,
        name: RouterNameEnum.DATA_OVERVIEW,
        meta: {
          layout: 'dashboardLayout',
          title: '数据总览',
        },
        component: () => import('@/views/dataOverview/index.vue'),
      },
      {
        path: PageEnum.RATE_FLOAT,
        name: RouterNameEnum.RATE_FLOAT,
        meta: {
          layout: 'dashboardLayout',
          title: '汇率波动',
        },
        component: () => import('@/views/rateFloat/index.vue'),
      },
      {
        path: PageEnum.CHANNEL_ANALYZE,
        name: RouterNameEnum.CHANNEL_ANALYZE,
        meta: {
          layout: 'dashboardLayout',
          title: '渠道监控',
        },
        component: () => import('@/views/channelAnalyse/index.vue'),
      },
      {
        path: PageEnum.CHANNEL_OVERVIEW,
        name: RouterNameEnum.CHANNEL_OVERVIEW,
        meta: {
          layout: 'dashboardLayout',
          title: '渠道总览',
        },
        component: () => import('@/views/channelOverview/index.vue'),
      },
      {
        path: PageEnum.AREA_ANALYZE,
        name: RouterNameEnum.AREA_ANALYZE,
        meta: {
          layout: 'dashboardLayout',
          title: '地区监控',
        },
        component: () => import('@/views/areaAnalyze/index.vue'),
      },
      {
        path: PageEnum.AREA_OVERVIEW,
        name: RouterNameEnum.AREA_OVERVIEW,
        meta: {
          layout: 'dashboardLayout',
          title: '地区总览',
        },
        component: () => import('@/views/areaOverview/index.vue'),
      },
      {
        path: PageEnum.CURRENCY_ANALYZE,
        name: RouterNameEnum.CURRENCY_ANALYZE,
        meta: {
          layout: 'dashboardLayout',
          title: '货币监控',
        },
        component: () => import('@/views/currencyAnalyze/index.vue'),
      },
      {
        path: PageEnum.CURRENCY_OVERVIEW,
        name: RouterNameEnum.CURRENCY_OVERVIEW,
        meta: {
          layout: 'dashboardLayout',
          title: '货币总览',
        },
        component: () => import('@/views/currencyOverview/index.vue'),
      },
      {
        path: PageEnum.PAY_CHANNEL,
        name: RouterNameEnum.PAY_CHANNEL,
        meta: {
          layout: 'dashboardLayout',
          title: '渠道付费数据',
        },
        component: () => import('@/views/payChannel/index.vue'),
      },
    ],
  },
  {
    path: PageEnum.AUTH_MANAGEMENT,
    name: RouterNameEnum.AUTH_MANAGEMENT,
    meta: {
      title: '用户和权限',
      icon: 'kopic-ic_yonghuhequanxian',
      isHideChildren: true, // 子级不展示在权限管理页面的菜单管理中
    },
    redirect: PageEnum.AUTH_MANAGEMENT,
    children: [
      {
        path: PageEnum.AUTH_MANAGEMENT,
        name: PageEnum.AUTH_MANAGEMENT,
        meta: {
          layout: 'dashboardLayout',
          title: '用户和权限',
        },
        component: () => import('@/views/authManagement/index.vue'),
      },
      {
        path: PageEnum.AUTH_USER_DETAIL,
        name: RouterNameEnum.AUTH_USER_DETAIL,
        meta: {
          layout: 'dashboardLayout',
          title: '创建用户',
        },
        component: () => import('@/views/authManagement/pages/userInfo.vue'),
      },
      {
        path: PageEnum.AUTH_ROLE_DETAIL,
        name: RouterNameEnum.AUTH_ROLE_DETAIL,
        meta: {
          layout: 'dashboardLayout',
          title: '创建角色',
        },
        component: () => import('@/views/authManagement/pages/roleInfo.vue'),
      },
    ],
  },
  {
    path: PageEnum.OPERATION_RECORD,
    name: RouterNameEnum.OPERATION_RECORD,
    meta: {
      layout: 'dashboardLayout',
      title: '操作日志',
      icon: 'kopic-ic_caozuorizhi',
    },
    component: () => import('@/views/operationRecord/index.vue'),
  },
  {
    path: PageEnum.PROJECT_ALL,
    name: RouterNameEnum.PROJECT_ALL,
    meta: {
      layout: 'dashboardLayout',
      title: '所有应用',
      icon: 'kopic-suoyouyingyong',
      isNotSelect: true, // 不展示在权限管理页面的菜单管理中
    },
    component: () => import('@/views/projectManage/projectAll/index.vue'),
  },
]
