<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "App",
  
})
</script>
<template>
  <a-config-provider :locale="locale" :theme="theme">
    <DashboardLayout v-if="isDashboardLayout">
      <RouterView :key="$route.fullPath" />
    </DashboardLayout>
    <LoginLayout v-else-if="isLoginLayout">
      <RouterView :key="$route.fullPath" />
    </LoginLayout>
    <RouterView v-else :key="$route.fullPath" />
  </a-config-provider>
</template>

<script setup lang="ts" name="App">
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { computed, defineAsyncComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useLocalStorage } from '@vueuse/core'

const DashboardLayout = defineAsyncComponent(
  () => import('@/layouts/dashboardLayout.vue')
)
const LoginLayout = defineAsyncComponent(
  () => import('@/layouts/loginLayout.vue')
)

dayjs.locale('zh-cn')
const locale = zhCN

const route = useRoute()
const isDashboardLayout = computed(
  () => route?.meta?.layout === 'dashboardLayout'
)
const isLoginLayout = computed(() => route?.meta?.layout === 'loginLayout')

const theme = {
  token: {
    colorPrimary: '#3d46f5',
  },
}
onMounted(() => {
  const currentForm = useLocalStorage('currentForm', {})
  const currentPagination = useLocalStorage('currentPagination', {
    current: 1,
    pageSize: 10,
  })
  currentForm.value = {
    time: [
      // this.$moment().subtract(6, 'days').format('YYYY-MM-DD'),
      // this.$moment().format('YYYY-MM-DD'),
    ],
    status: 'all',
    app: [],
    store_id: [],
    currency: [],
    currency_action: 'include',
    country: [],
    country_action: 'include',
    id: '',
    tid_or_mid: '',
    discount_rate: null,
    third_platform_code: [],
  }
  window.addEventListener('beforeunload', () => {
    console.log('页面即将刷新')
    currentForm.value = {
      time: [
        // this.$moment().subtract(6, 'days').format('YYYY-MM-DD'),
        // this.$moment().format('YYYY-MM-DD'),
      ],
      status: 'all',
      app: [],
      store_id: [],
      currency: [],
      currency_action: 'include',
      country: [],
      country_action: 'include',
      id: '',
      tid_or_mid: '',
      discount_rate: null,
      third_platform_code: [],
    }
    currentPagination.value = {
      current: 1,
      pageSize: 10,
    }
  })
})
</script>
