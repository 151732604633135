<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "Im30Table",
  
})
</script>
<template>
  <!-- 默认操作栏 -->
  <div
    v-if="props.isHandle"
    class="flex items-center justify-between w-full mb-5 handle-area"
  >
    <div class="left-box">
      <slot name="top-left" />
    </div>
    <div class="flex items-center right-box">
      <slot name="top-right" />
      <kop-button
        v-if="isShowExport"
        kopic-icon="kopic-daochu"
        :loading="exportLoading"
        :disabled="exportLoading"
        class="mr-4"
        icon-class="mr-1"
        @click="handleExport"
      >
        {{ exportLoading ? '正在导出' : '导出' }}
      </kop-button>
      <ColumnSetting
        v-if="props.isSetColumns"
        ref="column"
        v-model="columnsShow"
        :original="columnsOriginal"
        @change="emit('update:columns', columns)"
      />
    </div>
  </div>

  <!-- 表格 -->
  <a-table v-bind="$attrs" :columns="columnsShow" :pagination="innerPagination">
    <template v-if="$slots.title" #title>
      <slot name="title" />
    </template>
    <template #headerCell="{ column }">
      <slot name="headerCell" :column="column" />
    </template>
    <template #bodyCell="{ text, record, index, column }">
      <template v-if="(column as Im30TableColumnType)?.scopedSlots">
        <slot
          :name="(column as Im30TableColumnType).scopedSlots"
          :row="record as any"
          :text="text"
          :record="record as any"
          :index="index"
          :column="column as Im30TableColumnType"
        />
      </template>
    </template>
    <template v-if="$slots.footer" #footer>
      <slot name="footer" />
    </template>
  </a-table>
</template>

<script setup lang="ts" name="Im30Table">
import { ref, watch } from 'vue'
import { cloneDeep } from 'lodash-es'
import ColumnSetting from './columnSetting.vue'
import type { TableColumnType } from 'ant-design-vue'
import { computed } from 'vue'
import type { PaginationConfig } from 'ant-design-vue/es/pagination'

interface Im30TableColumnType extends Partial<TableColumnType> {
  scopedSlots?: string
  defaultHide?: boolean
}

interface Props {
  columns: any[]
  isHandle?: boolean
  isShowExport?: boolean
  isSetColumns?: boolean
  isShowPagination?: boolean
  pagination?: PaginationConfig
}

const props = withDefaults(defineProps<Props>(), {
  // 表格上方操作栏配置
  isHandle: true,
  isShowExport: true,
  // 表格字段配置
  columns: () => [],
  isSetColumns: true,
  isShowPagination: true,
  pagination: () => ({}),
})
const emit = defineEmits(['export', 'update:columns'])

const exportLoading = ref(false)

const handleExport = () => {
  exportLoading.value = true
  emit('export', () => {
    exportLoading.value = false
  })
}

const columnsShow = ref<Array<Im30TableColumnType>>([])
const columnsOriginal = ref<Array<Im30TableColumnType>>([])
watch(
  () => props.columns,
  (val: Array<Im30TableColumnType>) => {
    columnsOriginal.value = cloneDeep(val)
    columnsShow.value = columnsOriginal.value.filter(
      (item) => !item.defaultHide
    )
  },
  {
    immediate: true,
  }
)

const innerPagination = computed(() => {
  if (!props.isShowPagination) {
    return false
  }
  let inner = {
    pageSize: 10,
    current: 1,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50'] as (string | number)[],
    total: 0,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showTotal: (total: number, range: [number, number]) => `共 ${total} 条 `,
  }
  if (props?.pagination) {
    inner = {
      ...inner,
      ...props.pagination,
    }
  }
  return inner
})
</script>
