<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "ColumnSetting",
  
})
</script>
<template>
  <a-dropdown v-model:open="visible" :trigger="['click']">
    <div
      class="flex items-center justify-center border rounded-lg tab_column_option w-9 h-9"
      :class="{
        'btn-active': visible,
      }"
    >
      <kop-icon type="kopic-yingyongshezhi" class="text-16" />
    </div>
    <template #overlay>
      <div class="checkbox-wrapper">
        <a-checkbox
          v-model:checked="state.checkAll"
          :indeterminate="state.indeterminate"
          class="checkbox-item"
          @change="onCheckAllChange"
        >
          全部
        </a-checkbox>
        <a-checkbox-group v-model:value="state.checkedList" style="width: 100%">
          <Draggable
            item-key="key"
            handle=".handle"
            draggable=".item"
            :list="props.original"
            class="w-full"
            @start="dragging = true"
            @end="dragging = false"
            @change="columnOrderChange"
          >
            <template #item="{ element }">
              <div
                :class="{
                  relative: true,
                  item: !element.draggleDisabled,
                  'w-full': true,
                }"
                @mouseover="element.dragBtnVisible = true"
                @mouseleave="element.dragBtnVisible = false"
              >
                <a-checkbox
                  :value="element.key"
                  :disabled="element.draggleDisabled"
                  class="checkbox-item"
                >
                  {{ element.title }}
                </a-checkbox>
                <span
                  v-if="!element.draggleDisabled"
                  class="handle absolute top-[7px] right-[5px]"
                >
                  <kop-icon
                    v-show="element.dragBtnVisible"
                    type="kopic-tuodongqiehuan"
                    :class="[
                      element.draggleDisabled
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer',
                    ]"
                  />
                </span>
              </div>
            </template>
          </Draggable>
        </a-checkbox-group>
      </div>
    </template>
  </a-dropdown>
</template>

<script setup name="ColumnSetting" lang="ts">
import type { TableColumnType } from 'ant-design-vue'
import { computed, reactive, ref, watch } from 'vue'
import Draggable from 'vuedraggable'

const props = defineProps({
  // 表格字段配置
  modelValue: {
    type: Array<TableColumnType>,
    default() {
      return []
    },
  },

  original: {
    type: Array<TableColumnType>,
    default() {
      return []
    },
  },
})

const emit = defineEmits([
  'visible-change',
  'order-change',
  'update:modelValue',
])

const visible = ref(false)

const dragging = ref(false)

const plainOptions = computed(() =>
  props.original.map((col) => (col.key ? col.key : col.dataIndex))
)

const state = reactive<{
  indeterminate: boolean
  checkAll: boolean
  checkedList: string[]
}>({
  indeterminate: false,
  checkAll: false,
  checkedList: [],
})
watch(
  () => props.original,
  (val: Array<any>) => {
    state.checkAll = true
    state.checkedList = val
      .filter((item) => !item.defaultHide)
      .map((col) => (col.key || col.dataIndex) as string)
  },
  {
    immediate: true,
  }
)

const onCheckAllChange = (e: any) => {
  state.checkedList = (e.target.checked ? plainOptions.value : []) as string[]
  state.indeterminate = false
}

const findLatestColumns = () => {
  return props.original.filter((col: TableColumnType) => {
    const keyField = col.key ? col.key : col.dataIndex
    return keyField && state.checkedList.includes(keyField.toString())
  })
}

watch(
  () => state.checkedList,
  (val: string[]) => {
    state.indeterminate = !!val.length && val.length < plainOptions.value.length
    state.checkAll = val.length === plainOptions.value.length
    const checkedColumns = findLatestColumns()
    emit('visible-change', checkedColumns)
    emit('update:modelValue', checkedColumns)
  }
)

const columnOrderChange = () => {
  const checkedColumns = findLatestColumns()
  emit('order-change', checkedColumns)
  emit('update:modelValue', checkedColumns)
}
</script>

<style lang="less" scoped>
.checkbox-wrapper {
  @apply overflow-y-auto box-border rounded-lg bg-white;
  width: 200px;
  height: 266px;
  border: 1px solid #ebeef1;
  padding: 4px 2px;

  .item {
    .handle {
      margin-top: 0px;

      :deep(svg) {
        width: 16px;
        height: 16px;
      }
    }
  }

  .checkbox-item {
    @apply flex ml-0 rounded-lg box-border items-center px-2;
    margin-top: 2px;
    height: 36px;

    &:hover {
      background-color: #ebeef1;
    }
  }
}

.btn-active {
  background-color: #3d46f5;

  :deep(svg) {
    color: #fff;
  }
}
</style>
